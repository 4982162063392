<template>
  <div class="login">
    <div class="login-left">
      <div class="content">
        <r-link to="/">
          <logo class="logo" />
        </r-link>
        <h6>Sign up and start earning for FREE</h6>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
        >
          <a-row :gutter="24">
            <a-col
              :span="12"
            >
              <a-form-item>
                <input-text ref="firstname" placeholders="First Name" error-msg="Please input your First Name!" type="text" @setValue="getValue('firstname',$event)" />
              </a-form-item>
            </a-col>
            <a-col
              :span="12"
            >
              <a-form-item>
                <input-text ref="lastname" placeholders="Last Name (optional)" error-msg="Please input your Last Name!" type="text" @setValue="getValue('lastname',$event)" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <input-text ref="email" placeholders="Email" :error-msg="emailErrorMsg" type="text" @setValue="getValue('email',$event)" />
            <!-- <a-input
              v-decorator="[
                'email',
                {
                  rules: [
                    { required: true, message: 'Please input your email!' }
                  ]
                }
              ]"
              size="large"
              placeholder="email"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input> -->
          </a-form-item>
          <a-form-item>
            <input-text ref="password" placeholders="Password" error-msg="Please input your Password!" type="password" @setValue="getValue('password',$event)" />
            <!-- <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: 'Please input your Password!' }
                  ]
                }
              ]"
              size="large"
              type="password"
              placeholder="Password"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input> -->
          </a-form-item>
          <reCaptcha
            :sitekey="recaptchaKey"
            @getValidateCode="getValidateCode"
          />
          <a-form-item>
            <a-button
              style="width: 100%"
              type="none"
              html-type="submit"
              class="login-form-button"
              :class="getBtnIsDisabled ? 'btn-disabled' : ''"
              :loading="iconLoading"
            >sign up</a-button>
          </a-form-item>
        </a-form>
        <p class="register-msg">
          <!-- <a-checkbox v-model="isAgreePolicy" /> -->
          By clicking Sign up you agree to {{ appName }}app’s
          <a href="https://dogediy.com/terms-of-service/" target="_blank">Terms of Service,</a>
          <a href="https://dogediy.com/privacy-policy/" target="_blank">Privacy Policy</a>
          and
          <a href="https://dogediy.com/returns/" target="_blank">Return Policy</a>
        </p>
        <p class="new">
          Already have an account?
          <a @click="callNewPage('/login')">Sign in</a>
        </p>
      </div>
    </div>
    <common-right />
  </div>
</template>
<script>
import reCaptcha from '@/components/ReCaptcha'
import { Register } from '../../api/user'
import { mapGetters } from 'vuex'
import inputText from '@/components/inputText'
export default {
  components: {
    reCaptcha,
    inputText
  },
  data() {
    return {
      iconLoading: false,
      isAgreePolicy: true,
      recaptchaKey: process.env.VUE_APP_RECAPTCHAkEY, // recaptcha的key
      isRecaptcha: '', // 验证是否通过
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      emailErrorMsg: 'Please input your Email!'
    }
  },
  computed: {
    ...mapGetters('User', {
      appName: 'getAppName'
    }),
    getBtnIsDisabled() {
      if (!this.email || !this.password) {
        return true
      } else {
        return false
      }
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' })
    console.log(this.form)
  },
  methods: {
    getValue(name, value) {
      this[name] = value
    },
    // 获取recaptcha的token
    getValidateCode(value) {
      console.log('验证...' + value)
      this.isRecaptcha = value
    },
    async handleSubmit(e) {
      this.iconLoading = true
      e.preventDefault()
      if (!this.$refs.email.validateEmail()) {
        this.iconLoading = false
        this.emailErrorMsg = 'Email is not valid'
        return
      }
      if (this.$refs.email.validate() && this.$refs.password.validate()) {
        if (!this.isRecaptcha) {
          this.iconLoading = false
          const key = 'updatable'
          this.$message.error({ content: 'Please verify reCaptcha', key })
          return
        }
        const { code, msg } = await Register({
          recaptchaToken: this.isRecaptcha,
          email: this.email,
          password: this.password,
          firstname: this.firstname,
          lastname: this.lastname
        })
        if (code === 200) {
          this.iconLoading = false
          this.callNewPage({ path: '/login', query: { isAlert: true } })
        } else {
          this.iconLoading = false
          window.grecaptcha.reset()
          this.$message.error(msg)
        }
      } else {
        this.iconLoading = false
      }
    }
  }
}
</script>
<style lang="scss">
.login .login-form-button{
  margin-top: 24px;
  background-color: #1f1f1f !important;
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
  font-size: 16px;
  color: white !important;
  &:hover{
    background-color: rgba(31, 31, 31,0.8) !important;
  }
}
</style>
<style scoped lang="scss">
@import "../../assets/css/user.css";
.content{
  width: 456px;
  max-width: 456px !important;
}
h6{
  margin-bottom: 30px;
}
.login{
  background: white;
  position: fixed;
  height: 100%;
  width: 100%;
}
.login .register-msg{
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  a{
    color: #1f1f1f;
    text-decoration: underline;
  }
}
.login .new{
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  a{
    color: #1f1f1f;
    text-decoration: underline;
  }
}
.btn-disabled{
  background-color: RGBA(89, 89, 89, 1) !important;
}
</style>
